import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import upperFirst from 'lodash/upperFirst';
import pick from 'lodash/pick';
import PageHeader from '../generic/PageHeader';
import AzPage from '../generic/aToZ/Main';
import { promoShape } from '../promo/shapes';
import MetaProperties from '../generic/MetaProperties';

export const Page = ({
  baseURL,
  entity,
  letter,
  page,
  pages,
  promos,
  azCounts,
  totalCount,
  metaDescription,
}) => (
  <div className="az-page">
    <MetaProperties
      title={`${upperFirst(entity)} | ${letter.toUpperCase()}`}
      description={metaDescription}
      canonical={`${baseURL}/food/${entity}`}
    />
    <Helmet>
      {page > 1 && <link rel="prev" href={`${baseURL}/food/${entity}/a-z/${letter}/${page - 1}`} />}
      {page < pages && (
        <link rel="next" href={`${baseURL}/food/${entity}/a-z/${letter}/${page + 1}`} />
      )}
    </Helmet>
    <PageHeader className="index__title" title={upperFirst(entity)} />
    <AzPage
      azCounts={azCounts}
      baseUrl={`/food/${entity}`}
      activeTab="all"
      entity={entity}
      letter={letter}
      page={page}
      promos={promos}
      promoContainerClassName="az-page"
      totalCount={totalCount}
    />
  </div>
);

Page.defaultProps = {
  page: 1,
  pages: 1,
  promos: [],
  azCounts: {},
};

Page.propTypes = {
  baseURL: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  page: PropTypes.number,
  pages: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  totalCount: PropTypes.number.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default connect(state => ({
  baseURL: state.pageReducer.baseURL,
  ...pick(state.azPageReducer, [
    'entity',
    'letter',
    'page',
    'pages',
    'promos',
    'totalCount',
    'azCounts',
    'metaDescription',
  ]),
}))(Page);
